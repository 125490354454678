import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class LanguageService {
    constructor() {
        sessionStorage.setItem("lang", "en");
    }

    public currentLanguage$ = new BehaviorSubject<string>("en");
}
