<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 [title]="'APP.TITLES.GALLERY' | translate">
                {{ "APP.TITLES.GALLERY" | translate }}
            </h1>
        </div>
    </div>
</section>

<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/team_photo_1.jpg"
                    >
                        <img src="assets/img/team_photo_1.jpg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/team_photo_2.jpg"
                    >
                        <img src="assets/img/team_photo_2.jpg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/team_photo_3.jpeg"
                    >
                        <img src="assets/img/team_photo_3.jpeg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/ax_1.jpeg">
                        <img src="assets/img/ax_1.jpeg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/fb_1.jpg">
                        <img src="assets/img/fb_1.jpg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/team_photo_4.jpeg"
                    >
                        <img src="assets/img/team_photo_4.jpeg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/ground_1.jpeg">
                        <img src="assets/img/ground_1.jpeg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/siggi_photo_1.jpeg"
                    >
                        <img src="assets/img/siggi_photo_1.jpeg" alt="image" />
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a
                        data-fancybox="gallery"
                        href="assets/img/siggi_photo_2.jpeg"
                    >
                        <img src="assets/img/siggi_photo_2.jpeg" alt="image" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
