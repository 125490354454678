<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">{{
                        "APP.MENU_BAR.CONTACT" | translate
                    }}</span>
                    <h2>{{ "APP.LABELS.GET_IN_TOUCH" | translate }}</h2>
                    <p>
                        {{ "APP.PARA.CONTACT_PARA" | translate }}
                    </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <h3>Winter Training</h3>
                            <p>Wiro Sporthalle, Danzingerstr 45b, Rostock</p>
                            <hr />
                            <h3>Summer Training</h3>
                            <p>Hockeyplatz, Danzingerstr 45b, Rostock</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-24-hours"></i>
                            </div>
                            <h3>{{ "APP.MENU_BAR.CONTACT" | translate }}</h3>
                            <p class="contact-section">
                                Abteilungsleiter: Birgit Hakenberg
                                <span
                                    style="display: block"
                                    class="contact-box"
                                >
                                    <a
                                        href="https://wa.me/+4916094808034"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-whatsapp"></i>
                                    </a>

                                    <a href="tel:+4916094808034">
                                        <i class="bx bx-phone"></i> :
                                        (+49)-160-94808034
                                    </a>
                                </span>
                            </p>

                            <p class="contact-section">
                                Aziz Rahmati
                                <span
                                    style="display: block"
                                    class="contact-box"
                                >
                                    <a
                                        href="https://wa.me/+4915901462362"
                                        target="_blank"
                                    >
                                        <i class="bx bxl-whatsapp"></i>
                                    </a>

                                    <a href="tel:+4915901462362">
                                        <i class="bx bx-phone"></i> :
                                        (+49)-159-01462362
                                    </a>
                                </span>
                            </p>
                            <p>
                                Mail:
                                <a href="mailto:cricket.rostock@gmail.com"
                                    >cricket.rostock@gmail.com</a
                                >
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Social</h3>
                            <div class="social-box">
                                <a
                                    href="https://www.facebook.com/nikk.rostock"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                                <!-- <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a> -->
                                <a
                                    href="https://www.youtube.com/@hsgcricketrostock"
                                    target="_blank"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                                <a
                                    href="https://www.instagram.com/hsg_uni_rostock_cricket/"
                                    target="_blank"
                                >
                                    <i class="bx bxl-instagram"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h2>{{ "APP.LABELS.READY_GET_STARTED" | translate }}</h2>
                    <p>
                        {{ "APP.PARA.EMAIL_PRIVACY" | translate }}
                    </p>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        required
                                        [placeholder]="
                                            'APP.FORM.NAME' | translate
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        required
                                        [placeholder]="
                                            'APP.FORM.EMAIL' | translate
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        [placeholder]="
                                            'APP.FORM.PHONE_NUMBER' | translate
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        name="message"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        required
                                        [placeholder]="
                                            'APP.FORM.MESSAGE' | translate
                                        "
                                    ></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">
                                    {{ "APP.FORM.SEND_MESSAGE" | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
