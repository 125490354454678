import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";

@Component({
    selector: "app-sponsors-page",
    templateUrl: "./sponsors-page.component.html",
    styleUrls: ["./sponsors-page.component.scss"],
})
export class SponsorsPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        const elements = document.getElementsByClassName(".owl-carousel");
        for (let i = 0; i < elements.length; i++) {
            console.log(elements[i]);
        }
    }
}
