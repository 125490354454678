<div class="container">
    <div class="footer-content">
        <div class="logo">
            <a routerLink="/" class="d-inline-block"
                ><img src="assets/img/logo.png" alt="image"
            /></a>
            <ul class="footer-menu">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Legal</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Terms of Use</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Privacy policy</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Cockie setting</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Support center</a>
                </li>
            </ul>
        </div>
    </div>
</div>
Í

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-5">
                <p>
                    <i class="bx bx-copyright"></i>2021
                    <a href="https://hsgunirostock.de/" target="blank"
                        >Hsg Uni </a
                    >Cricket Rostock.
                </p>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-7">
                <div class="lang-switcher">
                    <span>Select your language</span>
                    <select (change)="onLanguageChanged($event)">
                        <option
                            value="en"
                            [selected]="
                                (languageService.currentLanguage$ | async) ===
                                'en'
                            "
                        >
                            English
                        </option>
                        <option
                            value="de"
                            [selected]="
                                (languageService.currentLanguage$ | async) ===
                                'de'
                            "
                        >
                            German
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
