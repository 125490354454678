<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 [title]="'APP.TITLES.CONTACT_US' | translate">
                {{ "APP.TITLES.CONTACT_US" | translate }}
            </h1>
        </div>
    </div>
</section>

<app-contact-form></app-contact-form>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
