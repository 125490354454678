<div class="navbar-area navbar-style-two">
    <div class="zinble-responsive-nav">
        <div class="container">
            <div class="zinble-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="zinble-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Home <i class="flaticon-down-arrow"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Main Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/esports"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Esports Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/bosting"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Bosting Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/magazine"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Magazine Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/tournaments"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Tournaments Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/streaming"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Streaming Home</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Pages <i class="flaticon-down-arrow"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/team"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Our Team</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/sponsors"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Our Sponsors</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/gallery"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Gallery</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ Page</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/coming-soon"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Coming Soon</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/error"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >404 Error Page</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Tournament <i class="flaticon-down-arrow"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/stream-schedule"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Stream Schedule</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-tournament"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Tournament Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-match"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Match Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-team"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Team Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-player"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Player Single</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Shop <i class="flaticon-down-arrow"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/products-list-1"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Products List 01</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/products-list-2"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Products List 02</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/cart"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Cart</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/checkout"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Checkout</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-products"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Products Details</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/my-account"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >My Account</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Blog <i class="flaticon-down-arrow"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-1"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Grid (2 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-2"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Grid (3 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-3"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Masonry (3 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-4"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Right Sidebar</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-5"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Left Sidebar</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-blog"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Blog Details</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Contact</a
                            >
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="cart-btn">
                                <a routerLink="/cart">
                                    <i class="flaticon-null-5"></i>
                                    <span>1</span>
                                </a>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i
                                    class="flaticon-null-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#sidebarModal"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="cart-btn">
                                <a routerLink="/cart">
                                    <i class="flaticon-null-5"></i>
                                    <span>1</span>
                                </a>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i
                                    class="flaticon-null-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#sidebarModal"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="sidebarModal modal right fade"
    id="sidebarModal"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block"
                        ><img src="assets/img/logo.png" alt="image"
                    /></a>
                </div>

                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member1.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member2.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member3.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member4.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member5.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member6.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar-contact-info">
                    <h3>
                        <a href="tel:+0002419268615">+000 241 926 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello@zinble.com">hello@zinble.com</a>
                    </h3>
                </div>

                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-facebook"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-youtube"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-twitch"></i
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input
                        type="text"
                        class="input-search"
                        placeholder="Search here..."
                    />
                    <button type="submit">
                        <i class="flaticon-search-1"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
