import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainHomeComponent } from "./components/pages/main-home/main-home.component";
import { EsportsHomeComponent } from "./components/pages/esports-home/esports-home.component";
import { BostingHomeComponent } from "./components/pages/bosting-home/bosting-home.component";
import { MagazineHomeComponent } from "./components/pages/magazine-home/magazine-home.component";
import { TournamentsHomeComponent } from "./components/pages/tournaments-home/tournaments-home.component";
import { StreamingHomeComponent } from "./components/pages/streaming-home/streaming-home.component";
import { GalleryPageComponent } from "./components/pages/gallery-page/gallery-page.component";
import { TeamPageComponent } from "./components/pages/team-page/team-page.component";
import { ContactPageComponent } from "./components/pages/contact-page/contact-page.component";
import { SponsorsPageComponent } from "./components/pages/sponsors-page/sponsors-page.component";
import { FaqPageComponent } from "./components/pages/faq-page/faq-page.component";
import { ComingSoonPageComponent } from "./components/pages/coming-soon-page/coming-soon-page.component";
import { ErrorPageComponent } from "./components/pages/error-page/error-page.component";
import { ProductsListPageOneComponent } from "./components/pages/products-list-page-one/products-list-page-one.component";
import { ProductsListPageTwoComponent } from "./components/pages/products-list-page-two/products-list-page-two.component";
import { CartPageComponent } from "./components/pages/cart-page/cart-page.component";
import { CheckoutPageComponent } from "./components/pages/checkout-page/checkout-page.component";
import { ProductsDetailsPageComponent } from "./components/pages/products-details-page/products-details-page.component";
import { ProfileAuthenticationPageComponent } from "./components/pages/profile-authentication-page/profile-authentication-page.component";
import { BlogGridThreeInRowPageComponent } from "./components/pages/blog-grid-three-in-row-page/blog-grid-three-in-row-page.component";
import { BlogDetailsPageComponent } from "./components/pages/blog-details-page/blog-details-page.component";
import { PlayerDetailsPageComponent } from "./components/pages/player-details-page/player-details-page.component";
import { TeamDetailsPageComponent } from "./components/pages/team-details-page/team-details-page.component";
import { MatchDetailsPageComponent } from "./components/pages/match-details-page/match-details-page.component";
import { TournamentsDetailsPageComponent } from "./components/pages/tournaments-details-page/tournaments-details-page.component";
import { StreamSchedulePageComponent } from "./components/pages/stream-schedule-page/stream-schedule-page.component";

const routes: Routes = [
    { path: "", component: MainHomeComponent },
    {
        path: "admin",
        loadChildren: () =>
            import("./admin/admin.module").then((m) => m.AdminModule),
    },
    { path: "esports", component: EsportsHomeComponent },
    { path: "bosting", component: BostingHomeComponent },
    { path: "magazine", component: MagazineHomeComponent },
    { path: "tournaments", component: TournamentsHomeComponent },
    { path: "streaming", component: StreamingHomeComponent },
    { path: "team", component: TeamPageComponent },
    { path: "gallery", component: GalleryPageComponent },
    { path: "sponsors", component: SponsorsPageComponent },
    { path: "faq", component: FaqPageComponent },
    { path: "coming-soon", component: ComingSoonPageComponent },
    { path: "error", component: ErrorPageComponent },
    { path: "schedule", component: StreamSchedulePageComponent },
    { path: "single-tournament", component: TournamentsDetailsPageComponent },
    { path: "single-match", component: MatchDetailsPageComponent },
    { path: "single-team", component: TeamDetailsPageComponent },
    { path: "single-player", component: PlayerDetailsPageComponent },
    { path: "products-list-1", component: ProductsListPageOneComponent },
    { path: "products-list-2", component: ProductsListPageTwoComponent },
    { path: "cart", component: CartPageComponent },
    { path: "checkout", component: CheckoutPageComponent },
    { path: "single-products", component: ProductsDetailsPageComponent },
    { path: "my-account", component: ProfileAuthenticationPageComponent },
    { path: "blog", component: BlogGridThreeInRowPageComponent },
    { path: "single-blog", component: BlogDetailsPageComponent },
    { path: "contact", component: ContactPageComponent },
    { path: "**", component: ErrorPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
