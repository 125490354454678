import { LanguageService } from "./../../../services/language.service";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-footer-style-one",
    templateUrl: "./footer-style-one.component.html",
    styleUrls: ["./footer-style-one.component.scss"],
})
export class FooterStyleOneComponent implements OnInit {
    constructor(public languageService: LanguageService) {}

    ngOnInit(): void {}

    onLanguageChanged(e) {
        const langKey = e.target.value;
        sessionStorage.setItem("lang", langKey);
        this.languageService.currentLanguage$.next(langKey);
    }
}
