<div class="container">
    <div class="section-title">
        <span class="sub-title">Our Valuable Partners</span>
    </div>

    <div class="partner-slides owl-carousel owl-theme">
        <div class="single-partner-item">
            <a class="d-block"
                ><img src="assets/img/logo_dosb.png" alt="image"
            /></a>
        </div>
        <div class="single-partner-item">
            <a
                href="https://www.ehrenamtsstiftung-mv.de"
                class="d-block"
                target="_blank"
                ><img src="assets/img/Logo-EAS-MV.jpeg" alt="image"
            /></a>
        </div>
        <div class="single-partner-item">
            <a class="d-block"
                ><img
                    src="assets/img/logo_integration_durch_Sport.jpg"
                    alt="image"
            /></a>
        </div>
        <div class="single-partner-item">
            <a
                href="https://www.youtube.com/watch?v=ZrvNQba8hkg"
                target="_blank"
                class="d-block"
                ><img src="assets/img/logo_lsb.jpeg" alt="image"
            /></a>
        </div>
        <div class="single-partner-item">
            <a href="https://hsgunirostock.de/" class="d-block" target="_blank"
                ><img src="assets/img/logo_HSG_icon.png" alt="image"
            /></a>
        </div>
    </div>
</div>
