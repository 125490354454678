<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Sponsors">Sponsors</h1>
        </div>
    </div>
</section>

<div class="partner-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Sponsors & Partners</span>
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a class="d-block"
                    ><img src="assets/img/logo_dosb.png" alt="image"
                /></a>
            </div>
            <div class="single-partner-item">
                <a
                    href="https://www.ehrenamtsstiftung-mv.de"
                    class="d-block"
                    target="_blank"
                    ><img src="assets/img/Logo-EAS-MV.jpeg" alt="image"
                /></a>
            </div>
            <div class="single-partner-item">
                <a class="d-block"
                    ><img
                        src="assets/img/logo_integration_durch_Sport.jpg"
                        alt="image"
                /></a>
            </div>
            <div class="single-partner-item">
                <a
                    href="https://www.youtube.com/watch?v=ZrvNQba8hkg"
                    target="_blank"
                    class="d-block"
                    ><img src="assets/img/logo_lsb.jpeg" alt="image"
                /></a>
            </div>
            <div class="single-partner-item">
                <a
                    href="https://hsgunirostock.de/"
                    class="d-block"
                    target="_blank"
                    ><img src="assets/img/logo_HSG_icon.png" alt="image"
                /></a>
            </div>
        </div>
    </div>
</div>

<!-- Other Sponsor banners -->

<!-- <div class="partner-area bg-image pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">GOLD SPONSORS</span>
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img1.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img2.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img3.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img4.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img5.png" alt="image"></a>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-1b060a pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Media Sponsors</span>
        </div>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img1.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img2.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img3.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img4.png" alt="image"></a>
            </div>
            <div class="single-partner-item">
                <a href="#" class="d-block"><img src="assets/img/partner-img5.png" alt="image"></a>
            </div>
        </div>
    </div>
</div> -->

<app-contact-form></app-contact-form>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
