<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Team">Team</h1>
        </div>
    </div>
</section>

<section class="team-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member1.jpg" alt="image" />

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Sarah3s</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member2.jpg" alt="image" />

                    <div class="content">
                        <h3>Rajesh</h3>
                        <span>Mangena</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/altbatman"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member3.jpg" alt="image" />

                    <div class="content">
                        <h3>Carla Gentry</h3>
                        <span>3s.King</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member4.jpg" alt="image" />

                    <div class="content">
                        <h3>Liam Oliver</h3>
                        <span>Junior.Zelda</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member5.jpg" alt="image" />

                    <div class="content">
                        <h3>Emma Olivia</h3>
                        <span>King.Zelda</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member6.jpg" alt="image" />

                    <div class="content">
                        <h3>Elijah William</h3>
                        <span>Zelda</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member8.jpg" alt="image" />

                    <div class="content">
                        <h3>Kylo Jennar</h3>
                        <span>Zelda.Kylo</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-team-member">
                    <img src="assets/img/team-member7.jpg" alt="image" />

                    <div class="content">
                        <h3>Lucas Luiz</h3>
                        <span>luiz.zelda</span>
                    </div>

                    <a
                        target="_blank"
                        href="https://instagram.com/hsg_uni_rostock_cricket"
                        class="link-btn"
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-form></app-contact-form>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
