<div class="navbar-area">
    <div class="zinble-responsive-nav">
        <div class="container">
            <div class="zinble-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo" width="50" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="zinble-nav">
        <div class="{{ containerClass }}">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                            >
                                Home
                            </a>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/gallery"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                >{{ "APP.MENU_BAR.GALLERY" | translate }}</a
                            >
                            <!--  <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/team"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Our Team</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/sponsors"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Our Sponsors</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/gallery"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Gallery</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ Page</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/coming-soon"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Coming Soon</a
                                    >
                                </li>
                            </ul> -->
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/sponsors"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                            >
                                {{ "APP.MENU_BAR.OUR_SPONSORS" | translate }}
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a
                                routerLink="/schedule"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                >Tournaments</a
                            >
                             <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/stream-schedule"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Stream Schedule</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-tournament"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Tournament Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-match"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Match Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-team"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Team Single</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-player"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Player Single</a
                                    >
                                </li>
                            </ul>
                        </li> -->

                        <li class="nav-item">
                            <a
                                routerLink="/blog"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                >Blog</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >{{ "APP.MENU_BAR.CONTACT" | translate }}</a
                            >
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <!-- <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div> -->

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i
                                    class="flaticon-null-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#sidebarModal"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i
                                    class="flaticon-null-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#sidebarModal"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="sidebarModal modal right fade"
    id="sidebarModal"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="logo instagram-list">
                    <div class="box">
                        <img src="assets/img/logo.png" alt="image" />
                        <i class="bx bxl-instagram"></i>
                        <a
                            href="https://www.instagram.com/hsg_uni_rostock_cricket/"
                            target="_blank"
                            class="link-btn"
                        ></a>
                    </div>
                </div>

                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member1.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member2.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member3.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member4.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member5.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img
                                    src="assets/img/team-member6.jpg"
                                    alt="image"
                                />
                                <i class="bx bxl-instagram"></i>
                                <a
                                    href="#"
                                    target="_blank"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar-contact-info">
                    <h3>
                        <a href="tel:+4916094808034">+49 160 94 8080 34</a>
                        <span>OR</span>
                        <a href="mailto:cricket.rostock@gmail.com"
                            >cricket.rostock@gmail.com</a
                        >
                    </h3>
                </div>

                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-facebook"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-youtube"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-twitch"></i
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input
                        type="text"
                        class="input-search"
                        placeholder="Search here..."
                    />
                    <button role="button" aria-label="submit" type="submit">
                        <i class="flaticon-search-1"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
