<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="main-banner-content">
            <span
                class="sub-title wow fadeInLeft"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
                >Cricket Rostock</span
            >
            <div
                class="logo wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                <img src="assets/img/logo.png" alt="image" width="150" />
            </div>
            <h6
                class="wow fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                HSG UNI Rostock
            </h6>
            <div
                class="btn-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                <a routerLink="/team" class="default-btn">Players</a>
                <a routerLink="/contact" class="optional-btn">Contact</a>
            </div>
        </div>

        <div class="banner-video-slides owl-carousel owl-theme">
            <div class="banner-video-box">
                <img src="assets/img/main-banner-video-img1.jpeg" alt="image" />

                <div class="content">
                    <a
                        href="https://www.youtube.com/watch?v=Okrq2jD3ZEY"
                        class="video-btn popup-youtube"
                        ><i class="flaticon-play"></i
                    ></a>
                    <span class="title"></span>
                </div>
            </div>
            <div class="banner-video-box">
                <img src="assets/img/main-banner-video-img2.jpeg" alt="image" />

                <div class="content">
                    <a
                        href="https://www.youtube.com/watch?v=RqsEdMfXv-E"
                        class="video-btn popup-youtube"
                        ><i class="flaticon-play"></i
                    ></a>
                    <span class="title">Official Trailer 2</span>
                </div>
            </div>
            <div class="banner-video-box">
                <img src="assets/img/main-banner-video-img3.jpeg" alt="image" />

                <div class="content">
                    <a
                        href="https://www.youtube.com/watch?v=fbP45L6uWNE"
                        class="video-btn popup-youtube"
                        ><i class="flaticon-play"></i
                    ></a>
                    <span class="title">Official Trailer 3</span>
                </div>
            </div>
        </div>
    </div>
</div>
