<div class="container">
    <div class="section-title">
        <span class="sub-title">Matches</span>
        <h2>Inter Deutschland University Tournament</h2>
    </div>

    <div class="single-matches-box">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="matches-team">
                    <img
                        src="assets/img/team1.png"
                        class="wow fadeInLeft"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Rostock</a></h3>
                        <ul class="watch-list">
                            <li><span>Watch</span></li>
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-12">
                <div class="matches-result">
                    <ul>
                        <li>140/2</li>
                        <li>120/6</li>
                    </ul>
                    <span class="date-time">20TH May 2020, 23:00</span>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="matches-team right-image">
                    <img
                        src="assets/img/team2.png"
                        class="wow fadeInRight"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Hannover</a></h3>
                        <ul class="watch-list">
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                            <li><span>Watch</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-box">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="matches-team">
                    <img
                        src="assets/img/team3.png"
                        class="wow fadeInLeft"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Rostock</a></h3>
                        <ul class="watch-list">
                            <li><span>Watch</span></li>
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-12">
                <div class="matches-result">
                    <ul>
                        <li>160/8</li>
                        <li>100/10</li>
                    </ul>
                    <span class="date-time">19TH May 2020, 22:00</span>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="matches-team right-image">
                    <img
                        src="assets/img/team4.png"
                        class="wow fadeInRight"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Berlin</a></h3>
                        <ul class="watch-list">
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                            <li><span>Watch</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-box">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="matches-team">
                    <img
                        src="assets/img/team5.png"
                        class="wow fadeInLeft"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Rostock</a></h3>
                        <ul class="watch-list">
                            <li><span>Watch</span></li>
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-12">
                <div class="matches-result">
                    <ul>
                        <li>100/5</li>
                        <li>104/2</li>
                    </ul>
                    <span class="date-time">18TH May 2020, 21:00</span>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="matches-team right-image">
                    <img
                        src="assets/img/team6.png"
                        class="wow fadeInRight"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Bremen</a></h3>
                        <ul class="watch-list">
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                            <li><span>Watch</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-box">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="matches-team">
                    <img
                        src="assets/img/team7.png"
                        class="wow fadeInLeft"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Rostock</a></h3>
                        <ul class="watch-list">
                            <li><span>Watch</span></li>
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-12">
                <div class="matches-result">
                    <ul>
                        <li>100/10</li>
                        <li>160/6</li>
                    </ul>
                    <span class="date-time">17TH May 2020, 24:00</span>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="matches-team right-image">
                    <img
                        src="assets/img/team8.png"
                        class="wow fadeInRight"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                        alt="image"
                    />

                    <div class="content">
                        <h3><a routerLink="/single-team">Hamburg</a></h3>
                        <ul class="watch-list">
                            <li>
                                <a
                                    href="#"
                                    target="_blank"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Watch on YouTube"
                                    ><i class="bx bxl-youtube"></i
                                ></a>
                            </li>
                            <li><span>Watch</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
