<div class="container">
    <div class="section-title">
        <span class="sub-title">Official</span>
        <h2>Stay Conected</h2>
    </div>

    <div class="row justify-content-around">
        <div class="col-lg-2 col-sm-4 col-md-4 col-6">
            <div class="single-social-box">
                <div class="content">
                    <i class="bx bxl-facebook"></i>
                    Facebook
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image" />
                    <img src="assets/img/social-shape3.png" alt="image" />
                </div>
                <a
                    href="https://www.facebook.com/nikk.rostock"
                    target="_blank"
                    class="link-btn"
                ></a>
            </div>
        </div>

        <!-- <div class="col-lg-2 col-sm-4 col-md-4 col-6">
            <div class="single-social-box">
                <div class="content">
                    <i class="bx bxl-twitter"></i>
                    Twitter
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image" />
                    <img src="assets/img/social-shape4.png" alt="image" />
                </div>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div> -->

        <!-- Instagram Icon -->
        <div class="col-lg-2 col-sm-4 col-md-4 col-6">
            <div class="single-social-box">
                <div class="content">
                    <i class="bx bxl-instagram"></i>
                    Instagram
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image" />
                    <img src="assets/img/social-shape6.png" alt="image" />
                </div>
                <a
                    href="https://www.instagram.com/hsg_uni_rostock_cricket"
                    target="_blank"
                    class="link-btn"
                ></a>
            </div>
        </div>
        <!-- Youtube Icon -->
        <div class="col-lg-2 col-sm-4 col-md-4 col-6">
            <div class="single-social-box">
                <div class="content">
                    <i class="bx bxl-youtube"></i>
                    YouTube
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image" />
                    <img src="assets/img/social-shape5.png" alt="image" />
                </div>
                <a
                    href="https://www.youtube.com/@hsgcricketrostock"
                    target="_blank"
                    class="link-btn"
                ></a>
            </div>
        </div>
    </div>
</div>
